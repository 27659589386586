import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EarthEveryChurchPage = ({ location }) => (
  <Layout location={location} title="Earth in Every Church">
    <SEO title="Earth in Every Church" />
    <section id="earth-every-church-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <p>
            This program acknowledges and encourages the impact of reflecting on
            the whole Earth as it intertwines with someone’s religious,
            spiritual and moral beliefs and worldview. All places of worship are
            invited to Fly the Earth Flag or an Earth Fine Art Print as a beacon
            of celebration.
          </p>
          <p>
            A simple image of Earth allows the families in your community to
            reflect and learn about our essential oneness from this perspective.
            We hope it builds stronger connections between people of different
            faiths, and the intersection of ecological sustainability and faith.
          </p>
          <div class="vimeo-embed-wrapper">
            <iframe
              src="https://player.vimeo.com/video/396303653"
              width="640"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen"
              title="Earth in Every Church"
              allowfullscreen
            ></iframe>
            <br />
            Video provided by Nawal Shahril, Islamic Society of Colorado Springs{" "}
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default EarthEveryChurchPage
